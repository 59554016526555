// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyAtDw4UklJNJiKtyatVaiwbWgLEmK84oQ0",
  authDomain: "indbnew4.firebaseapp.com",
  databaseURL: "https://indbnew4-default-rtdb.firebaseio.com",
  projectId: "indbnew4",
  storageBucket: "indbnew4.appspot.com",
  messagingSenderId: "592036077637",
  appId: "1:592036077637:web:bb5358bb3eeaeed5b0432c",
  measurementId: "G-PY573F4ZBB"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };